import React, { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
	className?: string
	children: ReactNode
}

export default function Title({ className, children }: Props) {
	return <span className={twMerge('inline-block text-3xl font-semibold', className)}>{children}</span>
}
