import { SalesTeam } from '@arckit/types'
import { Button } from '@arckit/ui'
import { IPageProps } from 'common-types'
import Layout from 'components/Layout'
import RepCard from 'components/contact-us/RepCard'
import RepCardSkeleton from 'components/contact-us/RepCardSkeleton'
import Resource from 'components/global/Resource'
import { H1 } from 'components/typography/Headings'
import React, { useEffect, useState } from 'react'

export interface ThankYouPageState {
	first_name: string
	params: string
	zip_code: string
	showPricingGuide?: boolean
	showSummerPricingGuide?: boolean
}

export default function ThankYouPage({ location: { state } }: IPageProps<ThankYouPageState>) {
	const [salesTeam, setSalesTeam] = useState<SalesTeam>()

	const getSalesTeam = async (zipCode: string) => {
		if (zipCode && zipCode.length === 5) {
			const response = await fetch(`https://quotes.americanreading.com/api/v1/salesforce/sales-team/zip-code/${zipCode}`, {
				method: 'GET'
			})
			const data: SalesTeam = await response.json()
			if (response.ok) {
				const salesTeam = data
				setSalesTeam(salesTeam)
			} else {
				const response = await fetch(`https://quotes.americanreading.com/api/v1/salesforce/sales-team/zip-code/19422`, {
					method: 'GET'
				})
				const data: SalesTeam = await response.json()
				if (response.ok) {
					const salesTeam = data
					setSalesTeam(salesTeam)
				}
			}
		}
	}

	useEffect(() => {
		if (state?.zip_code) getSalesTeam(state.zip_code)
	}, [state?.zip_code])

	return (
		<Layout pageName="Thank You">
			<div className="mt-10 px-4">
				<div className="mx-auto lg:w-2/3 2xl:w-1/3">
					<div className="relative mb-6 flex justify-center">
						<H1>
							<span className="relative leading-relaxed after:absolute after:-bottom-8 after:left-0 after:h-8 after:w-full after:rounded-[50%] after:border-[5px] after:border-transparent after:border-t-ab-100 sm:leading-normal">
								{state?.first_name ? `Thanks, ${state.first_name}!` : 'Thank you!'}
							</span>
						</H1>
					</div>
					<div className="mb-8 flex justify-center">
						<span className="text-center text-2xl font-medium">
							We've passed your request along to your ARC Account Manager<span>{salesTeam?.accountManager ? `, ${salesTeam.accountManager.name}.` : '.'}</span>
						</span>
					</div>
				</div>
				<div className="mx-auto mb-10 w-full lg:w-1/2">
					{salesTeam ? (
						<div className="flex flex-wrap">
							<RepCard data={salesTeam.accountManager} />
							<RepCard data={salesTeam.salesOperation} />
						</div>
					) : (
						<div className="flex gap-3">
							<RepCardSkeleton />
							<RepCardSkeleton />
						</div>
					)}
				</div>
				<div className="flex justify-center">
					{state?.showPricingGuide && (
						<Resource title="Pricing Guide" linkOnly>
							<Button>Download Pricing Guide</Button>
						</Resource>
					)}
					{state?.showSummerPricingGuide && (
						<Resource title="Summer Pricing Guide" linkOnly>
							<Button>Download Summer Pricing Guide</Button>
						</Resource>
					)}
				</div>
			</div>
			{state?.params && <iframe src={`https://info.americanreading.com/l/1029901/2023-08-07/2qgm?${state.params}`} width={1} height={1} />}
		</Layout>
	)
}
