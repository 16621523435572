import { Col } from 'components/grid'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

const RepCardSkeleton = () => {
	return (
		<Col width="w-full md:w-1/2" gutter="sm" className="mb-4 hidden opacity-75 sm:block">
			<div className="h-full w-full rounded border bg-white shadow-md">
				<div className="p-6">
					<Skeleton width="65%" height={25} className="mb-2" />
					<Skeleton width="50%" />
					<Skeleton width="80%" className="mt-3" />
					<Skeleton width="40%" className="mb-4" />
					<Skeleton width="90%" height={12} />
				</div>
			</div>
		</Col>
	)
}

export default RepCardSkeleton
