import { Col } from 'components/grid'
import React from 'react'
import { SalesTeamMember } from '@arckit/types'
import Title from 'components/typography/Title'
import { P } from 'components/typography/Paragraph'
interface Props {
	data: SalesTeamMember
}

const RepCard = ({ data }: Props) => {
	return (
		<Col width="w-full md:w-1/2" className="mb-4 px-0 md:px-2">
			<div className="h-full w-full rounded border bg-white shadow-md">
				<div className="p-6">
					<Title>{data.name}</Title>
					<P size="sm" className="italic">
						{data.title ? data.title : ''}
					</P>
					<span className="block truncate">
						<a href={`mailto:${data.email}`}>{data.email}</a>
					</span>
					<span className="mb-4 block text-slate-700">{data.phone}</span>
					<P size="xs">
						<span className="font-semibold text-slate-700">Can help with:</span>{' '}
						{data.title === 'Account Manager'
							? 'Placing an Order, Discussing Implementation, Attending an Event, Ordering Samples'
							: 'Shipment Status, Purchase Order Questions, Problems with your Order'}
					</P>
				</div>
			</div>
		</Col>
	)
}

export default RepCard
